import BoxTitle from "./BoxTitle";
import {Col, Row} from "antd";
import QueueAnim from "rc-queue-anim";
import defaultFace from "../static/tmp_face.png";
import React, {forwardRef, useEffect, useState} from "react";
import api from "../common/api";
import moment from "moment";
import Carousel from "./ScrollBox";

const AppRegUsers=forwardRef((props,ref)=>{


    const [list,setList]:any=useState([])


    useEffect(()=>{
        api.getNewUser(20).then((res)=>{
            console.table(res.data)
            setList(res.data)
        });
    },[])


    return (
        <>
        <Col key={"b1"} span={8}>
            <div className={"box"}>
                <div className={"tp"}>新注册用户</div>
                <div className={"ct"} >

                    <div style={{
                        margin: "0 1vw",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        height: '100%'
                    }}>
                        <BoxTitle title={""} path={""}/>
                        <Row style={{backgroundColor:"#2f669499"}} gutter={[10,10]}>
                            <Col xs={4}>身份</Col>
                            <Col xs={4}>机构</Col>
                            <Col xs={6}>登记类型</Col>
                            <Col xs={6}>时间</Col>
                            <Col xs={4}>照片</Col>
                        </Row>

                        <div style={{flex: 1, overflow: "hidden"}}>{
                            list.length>0?
                            <Carousel data={

                                list.map((v: any, i: number) => {
                                    return (
                                        <Row className={"scrollList"} key={i.toString()}
                                             style={{backgroundColor: i % 2 === 1 ? "#2f669499" : ""}}
                                             gutter={[10, 10]}>
                                            <Col xs={4} style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden"
                                            }}>{v.name}</Col>
                                            <Col xs={4} style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden"
                                            }}>{v.org.name}</Col>
                                            <Col xs={6} style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden"
                                            }}>{v.department}</Col>
                                            <Col xs={6} style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden"
                                            }}>{moment(new Date(v.createTime)).format("MM/DD HH:ss")}</Col>
                                            <Col xs={4} style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden"
                                            }}><img alt={"人脸"}
                                                    src={'http://guangdainyun.oss-cn-shanghai.aliyuncs.com' + v.facePath}
                                                    style={{
                                                        width: "1.4vw",
                                                        height: "1.4vw",
                                                        objectFit: "cover"
                                                    }}/></Col>
                                        </Row>
                                    )
                                })
                            }/>:null
                        }
                        </div>

                    </div>

                </div>
                <div className={"bt"}/>
            </div>
        </Col>
        </>
    )
})

export default AppRegUsers
