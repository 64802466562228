import React, {PureComponent} from "react";
import {InfoWindow, Map, Marker} from 'react-amap';
// @ts-ignore
import { Wrapper, DistrictExplorer } from 'react-amapui-wrapper'
import ReactDOM from "react-dom";
import state from "../store/state";
import store from "../store";

class XMap extends React.Component<any,any>{


    constructor(props:any,b:any) {
        super(props,b);
        console.log(store)
        this.state={
            ...this.props.store.getState()
        }
        this.props.store.subscribe(()=>{
            console.log("state_update");

            this.setState(this.props.store.getState())
        })
    }



    private mapEl:any=null;

    private windowEvents = {
        created: (iw:any) => {console.log(iw)},
        open: () => {console.log('InfoWindow opened')},
        close: () => {console.log('InfoWindow closed')},
        change: () => {console.log('InfoWindow prop changed')},
    }

    loadUI(){

    }

    render(){
        console.log(this.state);
        return (
<>
            <Map zoom={17} pitch={60} rotation={30} useAMapUI={true} viewMode={"3D"}
                 mapStyle={"amap://styles/blue"}
                 //center={{longitude: 120.98782, latitude: 31.400353}}
                 amapkey={"921277ce35acf8cfae720aa33f505c01"}
                 animateEnable={true}
                 events={{
                     created: (el: any) => {
                         this.mapEl = el;
                         console.log(el)
                         this.loadUI();
                         console.log(el);

                         // @ts-ignore
                         window.el=el;

                         setTimeout(()=>{
                             if(this.state.newRecord.value==0 ) {
                                 el.setFitView( el.getAllOverlays())
                             }
                          },2000)


                     },
                 }}>



                    {
                        this.props.devices.map((v: any, i: number, a: any) => {
                            if (!v.map) return null;
                            let map = JSON.parse(v.map);
                            if(!map.location||!map.location.lng) return null
                            return ( <Marker key={v.scanDeviceId+"_"+v.id}

                                             events={{
                                                 click:()=>{

                                                 }
                                             }}

                                             title={v.orgName+"\n"+v.device_name}
                                           position={{longitude: map.location.lng, latitude: map.location.lat}} />


                            )

                        })
                    }




                {
                    this.state.newRecord.value==0?null:<InfoWindow

                    position={this.state.newRecord.position}
                    closeWhenClickMap={false}
                    visible={this.state.newRecord.visible}
                    isCustom={false}
                    content={this.state.newRecord.content}
                    size={this.state.newRecord.size}
                    offset={this.state.newRecord.offset}
                    events={this.windowEvents}


                />}




            </Map>

            </>

        );
    }
}

export default XMap;


