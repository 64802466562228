//疫情盒子
import React from "react";

const YqCtBox=(props:{color:String,rotateY:Number,title:String,num:String})=>{
    return (
        <div style={{height:"7.5vw",width:"7vw",backgroundSize:"100% 100%",backgroundRepeat:"no-repeat",backgroundImage:(()=>{
                return (`url('data:image/svg+xml;charset=utf-8,`+encodeURIComponent(`
                            <svg style=" transform: rotateY(${props.rotateY}deg); " width="138" height="85"   xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 138 85">
                            <defs>
                                <linearGradient id="orange_red" x1="0%" y1="0%" x2="100%" y2="100%">
                                    rgb(255,0,0)
                                    <stop offset="0%"  stop-color="${props.color}" stop-opacity=".0"/>
                                    <stop offset="100%" stop-color="${props.color}" stop-opacity=".6"/>
                                </linearGradient>
                                <linearGradient id="orange_red2" x1="0%" y1="0%" x2="100%" y2="100%">
                                    rgb(255,0,0)
                                    <stop offset="0%"  stop-color="${props.color}" stop-opacity=".2"/>
                                    <stop offset="60%"  stop-color="${props.color}" stop-opacity=".3"/>
                                    <stop offset="100%" stop-color="${props.color}" stop-opacity="1"/>
                                </linearGradient>
                            </defs>
                            <path d="M1 1 L137 1 L137 55 C137 55 108 55 108 84 L1 84 L1 1 "
                                  fill="url(#orange_red)" stroke="url(#orange_red2)" stroke-width="1" />
                        </svg>`+"')"))
            })()
        }}>
            <div style={{paddingTop:"2.1vw",color:"#f0f2f5",fontSize:".8vw"}} className={"d1"}>{props.title}</div>
            <div style={{paddingTop:".2vw",fontSize:"1.2vw"}} className={"d2"}>{props.num}</div>
        </div>
    )
}
export default YqCtBox;
