import React from "react";

const DeviceStatus=(props:{per:number,title:string,color:string})=>{
    return (
        <div style={{height:"8.5vw",width:"7vw",backgroundSize:"100% 100%",backgroundRepeat:"no-repeat",position:"relative",margin:"auto"}}>
            <svg style={{width:"100%",height:"100%",position:"absolute",top:"0",left:"50%", marginLeft:"-3.5vw"}} viewBox={"0,0,100,120"}   xmlns="http://www.w3.org/2000/svg" version="1.1">
                <g width="100" height="120" fill="none" stroke="black" strokeWidth="4">
                    <circle cx="50" cy="50" r="40" stroke="#07FFFF"
                            strokeDasharray="4.19,4.19"
                            className="frdFan"
                            strokeWidth="4" fill="none"/>
                    <circle stroke={props.color}
                            cx="50"
                            cy="50"
                            strokeDasharray={(214*0.01*props.per)+","+(214*0.01*(100-props.per))}
                            strokeDashoffset="53.4"
                            r="34"
                            className="warterfan"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            strokeWidth="8"
                            fill="none"/>

                    <circle cx="50" cy="50" r="40" stroke="#07FFFF99"
                            strokeDasharray="4.19,12.57"
                            strokeDashoffset="0"
                            className="backFan"
                            strokeWidth="8" fill="none"/>
                    <circle stroke="#07484A"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            cx="50" cy="50" r="44" strokeWidth="4" fill="none"/>



                </g>
                <text x="50" y="50" textAnchor="middle" fill={"#fff"} fontSize="26px" dy="10px"   >{props.per}</text>
                <text x="50" y="105" textAnchor={"middle"}  fill={"#fff"} fontSize="15px" dy="10px"  >{props.title}</text>
            </svg>

        </div>
    )
}
export default DeviceStatus
