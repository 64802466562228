export default {
    token:"",
    newRecord:{
        visible: true,
        value: 0,
        position: [
            121.0,31.5
        ],
        offset: [0, 0],
        content:"testsetsetsetset"
    },
}
