import {Col, Row} from "antd";
import Carousel from "./ScrollBox";
import Reactm,{forwardRef} from "react";
import BoxTitle from "./BoxTitle";

const Zgfxdq=forwardRef((props:{data:Array<any>},ref)=>{
    return (
        <Col key={"b3"} span={8}>
            <div className={"box"}>
                <div className={"tp"}>中高风险地区</div>
                <div className={"ct"}>
                    <div style={{margin:"0 1vw",display:"flex",flexDirection:"column",position:"relative",height:'100%'}}>
                        <BoxTitle title={""} path={""} />
                        <Row style={{backgroundColor:"#2f669499"}} gutter={[10,10]}>
                            <Col xs={8}>地区</Col>
                            <Col xs={8}>本土病例</Col>
                            <Col xs={8}>现有病例</Col>
                        </Row>


                        <div style={{flex:1,overflow:"hidden"}}>
                            {props.data.length>0?<Carousel data={props.data.map((v:any,i,a)=>{
                                return (
                                    <Row

                                        className={"scrollList"} style={{backgroundColor:i%2===1?"#2f669499":""}} gutter={[10,10]}>
                                        <Col xs={8} style={{color:"#B27EC6",justifyContent:"center"}}><span>{v.city}</span>
                                            <span style={{border:"solid 1px #B27EC6",borderRadius:".5vw",lineHeight:"1vw", padding:"0 .2vw",fontSize:".5vw"}}>{v.province}</span> &gt;</Col>
                                        <Col xs={8} style={{color:"yellow"}}>{v.confirm}</Col>
                                        <Col xs={8}>{v.nowConfirm}</Col>
                                    </Row>
                                )
                            })} />:null}
                        </div>

                    </div>
                </div>
                <div className={"bt"}/>
            </div>
        </Col>
    )
})
export default Zgfxdq
