// 它就是将来真正要用到的数据，我们将其统一放置在reducers.js文件
import {combineReducers} from 'redux'
import defaultState from './state'

function token (state = defaultState.token,action:{type:string,data:any}) {
// 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'TOKEN':
            return action.data
        default:
            return state
    }
}

function newRecord(state:any=defaultState.newRecord,action:{type:string,data:any}){
    switch (action.type) {
        case 'NEW_RECORD':
            return action.data
        default:
            return state
    }
}


export default combineReducers({
    token,newRecord
})
