import React, { useState, useEffect } from 'react';
import { Area } from '@ant-design/charts';
import api from "../common/api";

const PersonLiveSum: React.FC = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        asyncFetch();
    }, []);
    const asyncFetch = () => {

        api.getRecordDayTotal().then(res=>{
            setData(res.data.reverse().map((v:any)=>{
                return  {Date:v[1],scales:v[0]}
            }));
        }).catch((error) => {
            console.log('fetch data failed', error);
        });
    };
    const config = {
        data: data,
        xField: 'Date',
        yField: 'scales',
        yAxis:{
            tickCount: 0,
        },
        stroke: 'black',
        xAxis:{
            range: [0, 1],
            tickCount: 0,
        },
        tooltip:{
            formatter: (datum:any ) => {
                return { name: "", value: "人流量"+datum.scales};
            },
        },
        areaStyle: function areaStyle() {
            return {
                stroke: 'black',
                fill: 'l(270) 0:rgba(255,255,0,.5) 0.3:rgba(255,255,0,255) 1:rgba(255,255,0,255)'
            };
        },
        smooth: true,
        line:{
            color:"#ffdd00",
        }
    };
    return <Area style={{height:"calc(100% - 9px)",width:"calc(100% - 20px)", margin:"auto", marginTop:"10px"}} {...config} />;
};

export default PersonLiveSum;
