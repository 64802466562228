import axios from "axios";
import qs from "qs"

const TencentYqApi="https://api.inews.qq.com/newsqa/v1/query/inner/publish/modules/list?modules=";
const ApiHost= "https://vl.techzhl.com";

/**
 * 获取浏览器参数
 * @param variable
 */
function getQueryVariable(variable:string):string | null{
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return null;
}
function U(path:string){
    let token=getQueryVariable("_token");
    console.log(token)
    return ApiHost+path+"?_token="+token
}


async function apiGet(path:string){
    return await axios.get(U(path),{withCredentials:true});
}

async function apiPost(path:string,data:any){
    return await axios.post(U(path),qs.stringify(data),{withCredentials:true,headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }});
}

export default  {
    token:getQueryVariable("_token"),
    getYqData:()=>axios.get(TencentYqApi+"statisGradeCityDetail,chinaDayList,chinaDayAddList,nowConfirmStatis,provinceCompare&t="+new Date().getTime()),
    getNewUser:(num:number)=>apiPost("/bv/get_new_person",{num:num}),
    getNewRecord:()=>apiGet("/bv/get_recent_record"),
    getUser:()=>apiGet("/bv/get_user"),
    getDevice:()=>apiGet("/bv/get_door_device"),
    getRecordDayTotal:()=>apiGet("/bv/get_record_day_total")
}
