import {Col, Row} from "antd";
import React from "react";
import * as path from "path";

const BoxTitle= (props:{title:String,path:String})=> {
    return (
        <Row gutter={[0,0]}>
            <Col xs={16} className={props.title?"title":""}>{props.title}</Col>
            <Col onClick={(e)=>{
                if(path){
                    // @ts-ignore
                    window.location.href = path
                }
            }} style={{textAlign:"right"}} xs={8}>更多&gt;</Col>
        </Row>
    );
}
export default BoxTitle;
