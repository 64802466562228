import React, { useEffect, useRef, useState } from "react";
import "./ScrollBox.css"



// @ts-ignore
const Carousel = ({ data }) => {
    // 此处data为父组件传过来的数据，为数组格式
    const [list]: any = useState(data);
    const [isScrolle, setIsScrolle] = useState(true);

    // 滚动速度，值越小，滚动越快
    const speed = 100;
    const warper: any = useRef();
    const childDom1: any = useRef();
    const childDom2: any = useRef();

    // 开始滚动
    useEffect(() => {
        // 多拷贝一层，让它无缝滚动
        childDom2.current.innerHTML = childDom1.current.innerHTML;
        let timer:any;
        if (isScrolle) {
            timer = setInterval(
                () => {
                    // 正常滚动不断给scrollTop的值+1,当滚动高度大于列表内容高度时恢复为0
                    warper.current.scrollTop >= childDom1.current.scrollHeight
                        ? (warper.current.scrollTop = 0)
                        : warper.current.scrollTop++
                },
                speed
            );
        }
        return () => {
            clearTimeout(timer);
        };
    }, [isScrolle]);


    const hoverHandler = (flag:boolean) => setIsScrolle(flag);


    return (
        <div className={"parent"} ref={warper}>
            <div className={"child"} ref={childDom1}>
                {list.map((v:any, i:number) => (
                    <div key={i.toString()}
                         onMouseOver={() => hoverHandler(false)}
                         onMouseLeave={() => hoverHandler(true)}>
                        {v}
                    </div>
                ))}
            </div>
            <div className={"child"} ref={childDom2} />
        </div>
    );
};

export default Carousel;

