import React, {useEffect} from 'react';
import './App.css';
import {Row, Col, Button, Tag} from 'antd';
import "antd/dist/antd.css";
import defaultFace from "./static/tmp_face.png";
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import PersonLiveSum from './compent/PersonLiveSum'
import XMap from "./compent/xmap";
import ReactDOM from "react-dom";
import axios, {AxiosRequestConfig} from "axios";
import Carousel from "./compent/ScrollBox";

import mqtt, {MqttClient} from 'mqtt'
import BoxTitle from "./compent/BoxTitle";

import Zgfxdq from "./compent/Zgfxdq"
import UserInItem from "./compent/UserInItem"

import YqCtBox from "./compent/YqCtBox"

import DeviceStatus from "./compent/DeviceStatus"
import AppSjyj from "./compent/AppSjyj";
import AppRegUsers from "./compent/AppRegUsers";

import moment from 'moment';

import api from './common/api'
import {Provider} from "react-redux";



const mqttOption={
    host: 'bv.techzhl.com/mqtt',
    port: 443,
    endpoint: '/static',
    clean: true, // 保留会话
    connectTimeout: 4000, // 超时时间
    reconnectPeriod: 4000, // 重连时间间隔
}


interface PassRecordItem {
    name:string,
    event:string,
    time:string,
    temp:string,
    userType:string,
    faceImage:string,
    gate:string,
    org:string
}

class App extends React.Component<any,any>{

    private _client:MqttClient|null=null;
    constructor(props:any) {
        super(props);
        //setToken()
        this.props.store.dispatch({type:"TOKEN",data:api.token})
        this.state=({
            show:false,
            //国内疫情
            yqCount:[{color:"#E02E2C",rotateY:0,title:"今日新增确诊",num:"55"},
                {color:"#00DADA",rotateY:180,title:"现有确诊",num:"2844"},
                {color:"#00DADA",rotateY:0,title:"累计确诊",num:"126155"},
                {color:"#00DADA",rotateY:180,title:"境外输入",num:"9654"}],
            //实时入场
            liveIn:[
                {type:"访客",num:36.6},
                {type:"住户",num:36.6},
                {type:"访客",num:37.1},
                {type:"住户",num:36.6}
            ],
            //中高风险地区
            ZGFXDZ:[1,2,3,4,5],
            offset:0,
            statisGradeCityDetail:[],

            client:null,
            setClient:null,
            record:[],
            user:null,
            devices:[]
        });
    }

    /**
     * 疫情数据初始化
     */
    async initYq(){

        let res=await api.getYqData();
        console.table(res.data.data.statisGradeCityDetail)
        let yqCount=this.state.yqCount;
        let today=res.data.data.chinaDayList[res.data.data.chinaDayList.length-1];
        let todayAdd=res.data.data.chinaDayAddList[res.data.data.chinaDayAddList.length-1]
        yqCount[0].num=todayAdd.localConfirmadd;
        yqCount[1].num=today.nowConfirm;
        yqCount[2].num=today.confirm;
        yqCount[3].num=today.importedCase;
        this.setState({
            statisGradeCityDetail:res.data.data.statisGradeCityDetail,
            yqCount:yqCount
        })
        return "YQ init ok";
    }







    /**
     * 初始化用户信息
     */
    async initUser(){
        let user=await api.getUser();
        if(user.data) {
            this.setState({...user.data})
            this.initMqtt(user.data.id)
            await this.initLiveUser()
            await this.getDevice();
            return user.data;
        }else{
            return null
        }
    }

    /**
     * 获取最近通行肌瘤
     */
    async initLiveUser(){
        let res=await api.getNewRecord();
        console.table(res.data)
        let records=res.data.map((v:any,i:number):PassRecordItem=>{
            return {
                name:v.personName,
                temp:v.bodyTemperature,
                event:v.verifyResult,
                userType:v.userType,
                time:moment(new Date(v.inoutTime)).format("MM/DD HH:mm"),
                faceImage:v.faceImage,
                gate:v.deviceName,
                org:v.org.name
            }
        })

        this.setState({record:records})
    }

    async getDevice(){
        let res=await api.getDevice();
        console.log("设备列表",res.data)
        if(res.data){
            this.setState({devices:res.data});
        }
        ReactDOM.render(
           <XMap devices={res.data} store={this.props.store} />,
            document.querySelector('#aMap')
        );
        return "map load";
    }

    /**
     * 初始化mqtt
     */
    initMqtt(uid:string){
        const { host, port, endpoint, ...options } = mqttOption
        const connectUrl = `mqtts://${host}:${port}`
        try {
            this._client = mqtt.connect(connectUrl)
            this._client.on('connect', () => {
                console.log('Connection succeeded!')
            })
            this._client.on('error', (error:any) => {
                console.log('Connection failed', error)
            })
            this._client.on('message', (topic:string, message:any) => {
                if(message.indexOf("body_temperature")!==-1){
                    let temp=JSON.parse(message);
                    let record=this.state.record;
                    const newRecord:PassRecordItem={
                        name:temp.person_name,
                        temp:temp.body_temperature,
                        event:temp.verify_result,
                        userType:temp.user_type,
                        time:moment(new Date(temp.inout_time)).format("MM/DD HH:mm"),
                        faceImage:temp.face_image,
                        gate:temp.device_name,
                        org:temp.org?temp.org.name:"-"
                    }
                    // @ts-ignore
                    record.unshift(newRecord)
                    record.pop()
                    // @ts-ignore
                    if(window.el){
                        const device_id=temp.device_id;
                        this.state.devices.forEach((v:any)=>{
                            if(v.id!=device_id||!v.scanDevice||!v.scanDevice.map) return;

                            let mi=JSON.parse(v.scanDevice.map);
                            console.log(mi);
                            // @ts-ignore
                            window.el.setZoomAndCenter(18,[mi.location.lng,mi.location.lat])
                            this.props.store.dispatch({type:"NEW_RECORD",data:{
                                    visible: true,
                                    value: 1,
                                    position: [mi.location.lng,mi.location.lat],
                                    offset: [0,-20],
                                    content:(


                                        `<div>
                                            <div>通行事件</div>
                                            <div >姓名:${newRecord.name}</div>
                                            <div >温度:${newRecord.temp}</div>
                                            <div>发生地:${newRecord.org}</div>
                                            <div>时间:${newRecord.time}</div>
                                            <div>门禁点:${newRecord.gate}</div>
                                        </div>`
                                    )
                                }})
                        })
                        //window.el.setCenter({})
                    }
                    this.setState({record:record})
                }
                console.log(`Received message ${message} from topic ${topic}`)
            })


            if(parseInt(uid)===1){
                this._client.subscribe(`device/all/record`)
            }else{
                this._client.subscribe(`device/${uid}/record`)
            }
            // @ts-ignore

        } catch (error) {
            console.log('mqtt.connect error', error)
        }
        return "ok"
    }

    componentDidMount() {
        this.initYq().then((res)=>console.log)
        this.initUser().then((res)=>console.log(res))
    }

    render() {

        let num1=0;
        let num2=0

        this.state.devices.forEach((v:any)=>{
            if(parseInt(v.device_status)!==29){
                num1++;
            }else{
                num2++;
            }
        })

        console.log("store",this.props.store.getState().token);
        return (
            <div className="App">
                <div className={"bg"}>

                    <div style={{width:"100vw",height:"100vh"}} id="aMap">
                        amp
                    </div>
                    <div  id="mapMask">

                    </div>

                    <div  style={{width:"100vw",height:"100vh",zIndex:8,position:"absolute",left:0,top:0}}>
                    <QueueAnim type={['top', 'bottom']}  delay={300}>
                        <div key={"top"} className={"top"}/>
                    </QueueAnim>
                    <QueueAnim type={['left', 'right']}  delay={400}>
                        <div  key={"leftBg"} className={"center11"}/>
                    </QueueAnim>
                    <QueueAnim type={['right', 'left']}  delay={400}>
                        <div  key={"rightBg"} className={"center12"}/>
                    </QueueAnim>

                        <QueueAnim type={'alpha'}  delay={300} duration={1000}>
                            <div key={"bottomBg"} className={"bottom"}/>
                        </QueueAnim>
                    </div>
                </div>
                <div className={"content"}>
                    <Row gutter={[24, 24]}>
                        <Col span={8} xs={8}>
                            <BoxTitle title={"实时入场"} path={""}/>
                            <QueueAnim component={Row as any} componentProps={{gutter:[5, 5],style:{marginTop: "12px"}}} type={['left', 'right']}  delay={30}>
                                {
                                    this.state.record.slice(0,4).map((item:PassRecordItem,i:number)=><Col key={i} xs={6}>{UserInItem(item)}</Col>)
                                }
                            </QueueAnim>
                        </Col>
                        <Col span={8}>

                        </Col>
                        <Col span={8}>
                            <BoxTitle title={"国内疫情"} path={""}/>
                            <QueueAnim component={Row as any} componentProps={{gutter:[10, 10]}} type={['right', 'left']}  delay={30}>

                                {
                                    this.state.yqCount.map((v:any,i:number)=>{
                                        return (
                                            <Col key={"count"+i} xs={6}>{YqCtBox(v)}</Col>
                                        )
                                    })
                                }
                            </QueueAnim>
                        </Col>
                    </Row>

                    <Row gutter={[24, 24]}>
                        <Col span={8}>
                            <div className={"box"}>
                                <div className={"tp"}>人流量趋势</div>
                                <div className={"ct"}>
                                    <PersonLiveSum />
                                </div>
                                <div className={"bt"}/>
                            </div>
                        </Col>
                        <Col span={8}>

                        </Col>
                        <Col span={8}>
                            <div className={"box"}>
                                <div className={"tp"}>设备状态</div>
                                <div className={"ct"}>
                                    <Row>
                                        <Col xs={12}  key={"a11"}>


                                            <DeviceStatus key={"a1"} per={num1} title={"在线数"} color={"#FB7293"} />
                                        </Col>
                                        <Col xs={12}  key={"a12"}>

                                            <DeviceStatus key={"a2"} per={num2} title={"离线数"} color={"#8276E7"} />
                                        </Col>
                                    </Row>

                                </div>
                                <div className={"bt"}/>
                            </div>
                        </Col>
                    </Row>

                    <QueueAnim component={Row as any} componentProps={{gutter:[24, 24]}}  leaveReverse={true} type={["scaleBig","alpha"]}   delay={0}>

                        {/*中高风险地址*/}
                        <AppRegUsers key={"b1"} />

                        {/*事件预警*/}
                        <AppSjyj key={"b2"} record={this.state.record} />
                        {/*中高风险地区*/}
                        <Zgfxdq key={"b3"} data={this.state.statisGradeCityDetail} />

                    </QueueAnim>

                </div>
            </div>
        );
    }
}

export default App;
