import BoxTitle from "./BoxTitle";
import {Col, Row, Tag} from "antd";
import Carousel from "./ScrollBox";
import React,{forwardRef} from "react";

interface PassRecordItem {
    name:string,
    event:string,
    time:string,
    temp:string,
    userType:string,
    faceImage:string,
    gate:string,
    org:string
}

const AppSjyj=forwardRef((props:{record:Array<PassRecordItem>},ref)=> {

    return (
        <Col key={"b2"} span={8}>
            <div className={"box"}>
                <div className={"tp"}>通行记录</div>
                <div className={"ct"}>
                    <div style={{
                        margin: "0 1vw",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        height: '100%'
                    }}>
                        <BoxTitle title={""} path={""}/>
                        <Row style={{backgroundColor: "#2f669499"}} gutter={[10, 10]}>
                            <Col xs={4}>姓名</Col>
                            <Col xs={4}>温度</Col>
                            <Col xs={4}>发生地</Col>
                            <Col xs={8}>时间</Col>
                            <Col xs={4}>门禁点</Col>
                        </Row>


                        <div style={{flex: 1, overflow: "hidden"}}>

                            {props.record.length>0?
                            <Carousel data={props.record.slice(0, 10).map((v: PassRecordItem, i, a) => {
                                return (

                                    <Row className={"scrollList"} key={i.toString()}
                                         style={{backgroundColor: i % 2 === 1 ? "#2f669499" : ""}} gutter={[10, 10]}>
                                        <Col xs={4}>{v.name}</Col>
                                        <Col xs={4}>{v.temp}</Col>
                                        <Col xs={4} style={{whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{v.org}</Col>
                                        <Col xs={8}>{v.time}</Col>
                                        <Col xs={4} style={{whiteSpace:"nowrap",textOverflow:"ellipsis"}}> {v.gate}</Col>
                                    </Row>
                                )
                            })}
                            />:null}
                        </div>
                    </div>
                </div>
                <div className={"bt"}/>
            </div>
        </Col>
    )
})

export default AppSjyj
