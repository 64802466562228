import React from "react";
////入场人员盒子
const UserInItem=(props:any)=>{
    return (
        <div className={"user_pic"}>
            <img src={props.faceImage} style={{width:"4.8vw",height:"3.4vw",objectFit:"cover"}} alt={props.userType}/>
            <div className={((num:Number)=>{
                if(num>37) return "d1 red";
                return "d1";
            })(props.temp)}>{props.temp}</div>

            <div className={"d2"}>{props.userType}</div>
        </div>
    )
}
export default UserInItem
